export const environment = {
  production: true,
  authenticated: false,
  hmr: false,
  ezlab_url: "vhub-api.easybox.io",
  websocketSchema: 'wss',
  schema: 'https',
  app: 'Ezlab',
  customerSiteName: "Easybox",
  siteDescription: "will assemble a team of experience CAD designer and assign to your account",
  companyLegal: "Easybox",
  companyLegalDomain: "easybox.io",
  siteDomain: "https://ezlab.easybox.io",
  logo: 'assets/images/easybox/logo.svg',
  favi: 'assets/images/cadlab3d/logo.png',
  appVersion: '1.1.17',
  paypalId: 'Aa4zdscjO3kMHpKa__fJeNsKY5EGZMIvLXHFxoqMiUI7XJTCzJ316r5YE-Ej84HaNN9lA2tyUnUewme9',
}

export const VERSION = {
  name: '1.0.0',
}
